/**
 * @extends GW.Class
 *
 * Base object that is:
 *
 * - __createable__ - you can create object instances using GW.create.
 * - __configurable__ - its constructor receives and applies a configuration object
 *   to the created instance,
 *   behavior in predicatble fashion,
 * - __observable__ - emits and listents to named events,
 * - __destructible__ - allow itself to be removed gracefully = require('the application.
 *
 * ### Constructing instances of GW.Object derived classes
 *
 * GW.Object is differnt to GW.Class which offers neither any special
 * functionality, nor any common way of construction.
 *
 * GW.Object instances are always constructed using one optional parameter:
 * a configuration object. All properties of configutaion object are applied
 * to the newly created instance.
 *
 * Some configuration properties are treated specially:
 *
 * __Method overrides:__
 *
 * You can prefix configuration property with extra instructions about what to
 * do with it. To override any class methods on the newly created instance only,
 * you can use following names:
 *
 * - before:methodName
 * - after:methodName
 * - chain:methodName
 * - wrap:methodName
 * - intercept:methodName
 *
 * See GW.override for more details.
 *
 * To replace the method completely, just use the method's name itself without
 * prefixing.
 *
 *     @example
 *     // Instance only method override example
 *
 *     var btn = new GW.Button({
 *       renderTo: 'body',
 *       text: 'I am a wrapped button',
 *       'after:renderComponent': function() {
 *         this.monDom(this.el, 'contextmenu', function(ev) {
 *           ev.preventDefault();
 *           this.fire('rightclick');
 *         }, this);
 *       }
 *     });
 *
 *     btn.on('rightclick', function() {
 *       print('Clicked right');
 *     });
 *
 *     btn.on('click', function() {
 *       print('Clicked any');
 *     });
 *
 * __Registering listeners:__
 *
 * To immediately start listening on events generated by the new instance, use:
 *
 * - on:eventname
 *
 * You can also use special `listeners` object to register event listeners, but
 * this is deprecated.
 *
 *     @example
 *     // Listener registration example
 *
 *     new GW.Button({
 *       renderTo: 'body',
 *       text: 'I am a button',
 *       'on:click': function() {
 *         print('Clicked!');
 *       }
 *     });
 *
 *
 * ### Producing (firing) and listening to events
 *
 * Events are one way in which one object may notify other objects of something
 * or other.
 *
 * To produce an event call {@link #fire} method:
 *
 *     var o = new GW.Object;
 *
 *     o.fire('name', 'argument 1', 'argument 2'); // ... you can have any
 *     // number of arguments
 *
 * To listen to events add listener function using {@link #on} method:
 *
 *     @example
 *     var o = new GW.Object({
 *       name: 'object 1'
 *     });
 *
 *     o.on('myevent', function(obj, msg) {
 *       print('Received ' + msg + ' = require('' + obj.name);
 *     });
 *
 *     o.fire('myevent', 'hi!');
 *
 * Events bubble to object owner, so if object has an owner you can also listen
 * on events using the owner object:
 *
 *     @example
 *     var owner = new GW.Object({
 *       name: 'owner'
 *     });
 *
 *     var child = new GW.Object({
 *       getParent() {
 *         return owner;
 *       },
 *       name: 'child'
 *     });
 *
 *     // we need to pass true to the third argument (`captureBubbles`)
 *     // to enable listening for bubbled events
 *     owner.on('myevent', function(obj, msg) {
 *       print('Received ' + msg + ' = require('' + obj.name);
 *     }, null, true);
 *
 *     child.fire('myevent', 'hi!');
 *     owner.fire('myevent', 'good!');
 *
 * Owner property is automatically set by containers when children are added, so
 * that events bubble up through the component's hierarchy.
 *
 * You can of course create your own event bubbling hierarchies = require('other
 * GW.Object derived classes, as demonstrated above.
 *
 * ### Destruction
 *
 * It important to free resources used by objects correctly. To do that, you can
 * override {@link #destroy} method.
 *
 * To help you with releasing listeners on objects that are not owned by the
 * currently destroyed object, you can use {@link #mon} method to register the
 * listener.
 *
 * These listeners will be released automatically.
 *
 * You don't need to release listeners on objects that are going to be
 * destroyed.
 *
 * Don't forget to release other resources:
 *
 * - remove window.setInterval timers
 * - abort asynchronous actions that may report back to this object, or make
 *   sure they check if the object is still active
 *
 */
GW.define('GW.Object', {
	/**
	 * @event destroy
	 * Fired while the object is being destroyed.
	 * @param {GW.Object} obj
	 */

	/**
	 * @constructor
	 * @param {Object} config Configuration object.
	 */
	constructor: function(config) {
		this.applyConfig(config);
		this.initObject();
	},

	/**
	 * @private
	 * Apply configuration object to the instance.
	 * @param {Object} config Configuration object.
	 */
	applyConfig: function(config) {
		var overrideSpec = {};
		var m;

		for (var k in this) {
			if ((m = k.match(/^(on\*?):(.+)$/))) {
				this.on(m[2], this[k], this, m[1] == 'on*');
			}
		}

		Object.entries(config || {}).forEach(function([k, v]) {
			var m;

			if (k == 'constructor') {
				throw new Error('Trying to overwrite constructor of ' + this.constructor.__className__);
			}

			if (typeof v == 'function') {
				v.displayName = this.constructor.__className__ + '.' + k + '[config,inst]';
			}

			if ((m = k.match(/^([^:]+):(.+)$/))) {
				if (m[1] == 'on' || m[1] == 'on*') {
					this.on(m[2], v, this, m[1] == 'on*');
				} else {
					overrideSpec[k] = v;
				}
			} else {
				this[k] = v;
			}
		}, this);

		GW.override(this, overrideSpec);
	},

	/**
	 * Initialize object.
	 * @redef
	 */
	initObject: function() {
	},

	/**
	 * Destroy object.
	 * @fires destroy
	 */
	destroy: function() {
		this.fire('destroy');
		this.destroyObservable();
	},

        // objects are observable

	/**
	 * Listen to events produced by this object.
	 * @param {String} name Name of the event.
	 * @param {Function} fn Event handler function.
	 * @param {Object} scope Scope for the event handler
	 * @param {Boolean} captureBubbles Listen for events on all child
	 * components in addition to this object's events.
	 * @return {Object} Event control object
	 * @return {Function} return.remove Remove this event listener.
	 */
	on: function(name, fn, scope, captureBubbles) {
		var me = this;
		var l = {
			id: GW.uniqueId('ol'),
			name: name,
			fn: fn,
			scope: scope,
			remove: function() {
				if (this.id) {
					delete me._events[this.name][this.id];
					delete this.id;
				}
			},
			captureBubbles: captureBubbles,
			fire: function(args) {
				if (this.id) {
					this.fn.apply(this.scope || this, args);
				}
			}
		};

		fn.displayName = this.constructor.__className__ + '.' + name + '[listener]';
		l.fire.displayName = 'GW.Object.on/listener.fire[internal]';

		this._events = this._events || {};
		this._events[name] = this._events[name] || {};
		this._events[name][l.id] = l;

		return l;
	},

	/**
	 * Listen to events produced by `obj`. This listener will be
	 * automatically removed = require('`obj` when this object is destroyed.
	 * @param {GW.Object} obj Object to listen on.
	 * @param {String} name Name of the event.
	 * @param {Function} fn Event handler function.
	 * @param {Object} scope Scope for the event handler
	 * @param {Boolean} captureBubbles Listen for events on all child
	 * components in addition to this object's events.
	 * @return {Object} Event control object
	 * @return {Function} return.remove Remove this event listener.
	 */
	mon: function(obj, name, fn, scope, captureBubbles) {
		var me = this;
		var l = obj.on(name, fn, scope, captureBubbles);

		this._monListeners = this._monListeners || {};
		this._monListeners[l.id] = l;

		// wrap remove so that it clean up _monListeners in addition to the obj's _events
		var remove = l.remove;
		l.remove = function() {
			if (this.id) {
				delete me._monListeners[this.id];
			}

			remove.call(l);
		};

		return l;
	},

	/**
	 * Listen to events produced by `target`. This listener will be
	 * automatically removed = require('`target` when this component is destroyed.
	 * @param {EventTarget} target Object to listen on (may be anything that
	 * impelements EventTarget interface, eg. Element, Window, Document, ...).
	 * @param {String} name Name of the event.
	 * @param {Function} fn Event handler function.
	 * @param {Object} scope Scope for the event handler
	 * @param {Boolean} captureBubbles
	 * @return {Object} Event control object
	 * @return {Function} return.remove Remove this event listener.
	 */
	monDom: function(target, name, fn, scope, captureBubbles) {
		var me = this;
		var cb = scope ? fn.bind(scope) : fn;

		target.addEventListener(name, cb, captureBubbles);

		var l = {
			id: GW.uniqueId('dm'),
			remove: function() {
				if (this.id) {
					target.removeEventListener(name, cb, captureBubbles);
					delete me._monDomListeners[this.id];
					delete this.id;
				}
			}
		};

		this._monDomListeners = this._monDomListeners || {};
		this._monDomListeners[l.id] = l;

		return l;
	},

	/**
	 * Fire event.
	 * @param {String} name Event name
	 * @param {Mixed...} args Event arguments
	 */
	fire: function() {
		var args = Array.from(arguments), name = args.shift();

		args.unshift(this);

		var target = this;
		while (target) {
			var listeners = target._events && target._events[name];
			if (listeners) {
				for (var k in listeners) {
					if (listeners.hasOwnProperty(k) && (listeners[k].captureBubbles || target === this)) {
						listeners[k].fire(args);
					}
				}
			}

			target = target.getParent();
		}
	},

	getParent: function() {
	},

	/**
	 * @private
	 * Destroy object's observable state. Removes all listeners.
	 */
	destroyObservable: function() {
		if (this._events) {
			for (let eg of Object.values(this._events)) {
				for (let ev of Object.values(eg)) {
					ev.remove();
				}
			}
		}

		if (this._monListeners) {
			Object.values(this._monListeners).forEach(l => l.remove());
		}

		if (this._monDomListeners) {
			Object.values(this._monDomListeners).forEach(l => l.remove());
		}

		delete this._events;
		delete this._monListeners;
		delete this._monDomListeners;
	}
});

/**
 * @class GW
 * @singleton
 */

/**
 * Create an instance of a class = require('a configuration object.
 *
 * __Examples:__
 *
 *     var o = GW.create({});
 *     print(o.constructor.__className__);
 *     // --> GW.Component
 *
 *     var o = GW.create({}, 'GW.TextField');
 *     print(o.constructor.__className__);
 *     // --> GW.TextField
 *
 *     var o = GW.create({xtype: 'GW.Combo'}, 'GW.TextField');
 *     print(o.constructor.__className__);
 *     // --> GW.Combo
 *
 * @param {Object} o Configuration object that will be passed to the
 * constructor. It must contain `xtype` property.
 * @param {String} [defaultType=GW.Component] What xtype to use if it is not
 * specified in the configuration object.
 * @return {Mixed} New instance, or `o` if o is already an instance of some
 * GW.Class derived object.
 */
GW.create = function(o, defaultType) {
	if (o instanceof GW.Class) {
		return o;
	}

	var type = o.xtype || defaultType || 'GW.Component';
	var cls = GW.Class.__classNameIndex__[type] || null;
	if (cls) {
		return new cls(o);
	} else {
		throw new Error('Trying to create undefined type ' + type);
	}
};
